import * as React from 'react';

import { SearchDocumentType } from '@wix/client-search-sdk';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';

import { ISampleLayoutProps, IListLayoutItemProps } from '../Layout.types';
import { Title } from '../Title';
import { ILayoutItem } from '../LayoutItem.types';
import {
  ISearchResultsLayoutContext,
  SearchResultsLayoutContext,
} from '../SearchResultsLayoutContext';
import { ListLayoutItem } from '../ListLayout';
import { GridLayoutItem } from '../GridLayout';
import { ViewAllButton } from '../ViewAllButton';
import { LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH } from '../ListLayout/ListLayoutItem';
import {
  EventListItem,
  EventListItemRenderMode,
} from '../EventList/EventListItem';

import { st, classes } from './SampleLayout.st.css';

const THUMBNAIL_WIDTH = {
  DESKTOP: 102,
  MOBILE: 102,
};

const getThumbnailWidth = ({
  isMobile,
  isFluid,
}: ISearchResultsLayoutContext) =>
  isFluid
    ? LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH
    : isMobile
    ? THUMBNAIL_WIDTH.MOBILE
    : THUMBNAIL_WIDTH.DESKTOP;

type ISample = ISampleLayoutProps['results'][0];
const enum LayoutType {
  grid = 'grid',
  list = 'list',
  events = 'events',
}

const typeLayoutMap: Record<SearchDocumentType, LayoutType | null> = {
  [SearchDocumentType.All]: null,
  [SearchDocumentType.Pages]: LayoutType.list,
  [SearchDocumentType.Products]: LayoutType.grid,
  [SearchDocumentType.Blogs]: LayoutType.list,
  [SearchDocumentType.Forums]: LayoutType.list,
  [SearchDocumentType.Bookings]: LayoutType.list,
  [SearchDocumentType.Events]: LayoutType.events,
};

class SampleLayoutComponent extends React.Component<
  ISampleLayoutProps & WithTranslation
> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  getRenderItemProps = (
    item: ILayoutItem,
    index: number,
  ): IListLayoutItemProps & { key: any } => {
    const onClick = (e: React.MouseEvent<HTMLElement>) =>
      this.props.onItemClick && this.props.onItemClick(e, item, index);

    const onLinkClick = (e: React.MouseEvent<HTMLElement>) =>
      this.props.onItemLinkClick && this.props.onItemLinkClick(e, item, index);

    const {
      formatCurrency,
      formatDate,
      formatTime,
      isWideThumbnail,
      t,
    } = this.props;

    return {
      formatCurrency,
      formatDate,
      formatTime,
      isWideThumbnail,
      item,
      key: index,
      onClick,
      onLinkClick,
      t,
    };
  };

  renderListLayoutItem = (item: ILayoutItem, index: number) => {
    return (
      <ListLayoutItem
        {...this.getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(this.context)}
      />
    );
  };

  renderGridLayoutItem = (item: ILayoutItem, index: number) => {
    return <GridLayoutItem {...this.getRenderItemProps(item, index)} />;
  };

  renderEventsLayoutItem = (item: ILayoutItem, index: number) => {
    const { isFluid } = this.context;
    return (
      <EventListItem
        {...this.getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(this.context)}
        renderMode={
          isFluid
            ? EventListItemRenderMode.default
            : EventListItemRenderMode.condensed
        }
      />
    );
  };

  renderSampleGroup = (sample: ISample) => {
    const { onViewAllClick } = this.props;

    if (!sample.documents.length) {
      return;
    }

    const layoutType = typeLayoutMap[sample.documentType] || LayoutType.list;
    const renderItem = {
      grid: this.renderGridLayoutItem,
      list: this.renderListLayoutItem,
      events: this.renderEventsLayoutItem,
    }[layoutType];
    const sampleGroupLabel = sample.documentType?.split('/').pop();

    return (
      <div
        data-hook={`sample-layout-group-${sampleGroupLabel}`}
        className={classes.group}
        key={sample.documentType}
      >
        <Title
          data-hook="sample-layout-group-title"
          role="heading"
          title={sample.title}
          onClick={e => onViewAllClick(e, sample.documentType)}
          url={sample.url}
        />

        <ul
          data-hook="sample-layout-group-items"
          role="list"
          className={st(classes.groupItems, { layout: layoutType })}
        >
          {sample.documents.map(renderItem)}
        </ul>
        <ViewAllButton
          url={sample.url}
          onClick={e => onViewAllClick(e, sample.documentType)}
          data-hook="sample-layout-group-view-all"
        />
      </div>
    );
  };

  render() {
    const { isMobile, isFluid } = this.context;
    return (
      <div
        className={st(classes.root, {
          fixed: !isFluid,
          fluid: isFluid,
          mobileView: isMobile,
        })}
        data-hook="sample-layout"
        tabIndex={-1}
        role="region"
        aria-label={this.props.label}
      >
        {this.props.results.map(this.renderSampleGroup)}
      </div>
    );
  }
}

export const SampleLayout = withTranslation()(SampleLayoutComponent);
